import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import ProgramLinks from '../components/ProgramLinks'

const Landing = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Cosmetology</title>
      <meta name="description" content="Cosmetology" />
    </Helmet>

    <BannerLanding
      title="Cosmetology"
      subtitle="hair, skin, nails"
      color="cosmetology"
    />

    <div id="main">
      <section id="one">
        <div className="inner">
          <p>Program Level - Graduate Certificate</p>
          <p>Program Length - 54 weeks</p>
          <header className="major">
            <h2>Performance Objectives</h2>
          </header>
          <ul>
            <li>
              Acquire knowledge of laws and rules regulating California’s
              cosmetology establishment practices.
            </li>
            <li>
              Acquire the knowledge of sanitation and sterilization as related
              to all functions of hair, skin, and nails.
            </li>
            <li>
              Acquire the knowledge of subjects relative to cosmetology
              including anatomy, physiology, chemistry and the theory relative
              to the practical procedures to be performed.
            </li>
          </ul>
          <header className="major">
            <h2>Skills to Be Developed</h2>
          </header>
          <ul>
            <li>
              The proper use of implements relative to all cosmetology services.
            </li>
            <li>
              The ability to properly analyze the scalp, face and hands to
              identify any disorders prior to performing services.
            </li>
            <li>
              The procedures and terminology used in performing all cosmetology
              services.
            </li>
            <li>
              The application of daytime and evening make-up, including the
              application of false strip eyelashes.
            </li>
            <li>
              The proper procedure of manicuring to include water and oil
              manicure and pedicure.
            </li>
            <li>
              The application of brushed-on nails, nail wraps, and nail tips.
            </li>
          </ul>
          <header className="major">
            <h2>Attitudes and Appreciations to Be Developed</h2>
          </header>
          <ul>
            <li>Learn to appreciate good workmanship common to cosmetology.</li>
            <li>
              Develop a positive attitude toward the public and your fellow
              workers.
            </li>
            <li>Learn and Appreciate honesty and integrity.</li>
            <li>
              Learn to act professionally, especially when dealing with patrons
              and colleagues.
            </li>
          </ul>
        </div>
      </section>
      <ProgramLinks ge="cosmetology" />
    </div>
  </Layout>
)

export default Landing
